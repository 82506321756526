@import url("/assets/fonts/fa/css/all.min.css");
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/opensans/OpenSans-Light.woff2") format("woff2"), url("/assets/fonts/opensans/OpenSans-Light.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: url("/assets/fonts/opensans/OpenSans-LightItalic.woff2") format("woff2"), url("/assets/fonts/opensans/OpenSans-LightItalic.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/opensans/OpenSans-Regular.woff2") format("woff2"), url("/assets/fonts/opensans/OpenSans-Regular.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: url("/assets/fonts/opensans/OpenSans-Italic.woff2") format("woff2"), url("/assets/fonts/opensans/OpenSans-Italic.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("/assets/fonts/opensans/OpenSans-SemiBold.woff2") format("woff2"), url("/assets/fonts/opensans/OpenSans-SemiBold.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: url("/assets/fonts/opensans/OpenSans-SemiBoldItalic.woff2") format("woff2"), url("/assets/fonts/opensans/OpenSans-SemiBoldItalic.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/opensans/OpenSans-Bold.woff2") format("woff2"), url("/assets/fonts/opensans/OpenSans-Bold.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: url("/assets/fonts/opensans/OpenSans-BoldItalic.woff2") format("woff2"), url("/assets/fonts/opensans/OpenSans-BoldItalic.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/opensans/OpenSans-ExtraBold.woff2") format("woff2"), url("/assets/fonts/opensans/OpenSans-ExtraBold.woff") format("woff");
  font-display: swap;
}
/* timer vars */
:root {
  --bg-color: #1c1c1e;
  --inactive: rgba(255, 255, 255, 0.22);
  --active: green;
  --warn: #ff8d00;
  --time-out: red;
  --text-color: rgba(255, 255, 255, 0.9);
  --blink: darkred;
}

.timerScreen {
  background-color: var(--bg-color);
  color: var(--text-color);
}
.timerScreen .logo {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
}
.timerScreen .logo img {
  width: 160px;
}
.timerScreen .timer {
  color: green;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}
.timerScreen .timer,
.timerScreen .time.center {
  position: fixed;
  top: 21vh;
  width: 100%;
  text-align: center;
  margin-left: unset;
  font-size: 16vw;
  font-weight: 600;
}
.timerScreen .dateTime {
  position: absolute;
  bottom: 165px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.timerScreen .dateTime.center {
  justify-content: center;
}
.timerScreen .dateTime.center .time,
.timerScreen .dateTime.center .date {
  margin: unset;
}
.timerScreen .time {
  margin-left: 4vw;
  font-size: 6vw;
}
.timerScreen .date {
  margin-right: 4vw;
  font-size: 3.2vw;
}
.timerScreen .dots {
  -webkit-transition-duration: 0.8s;
  transition-duration: 0.8s;
}
.timerScreen .inactive {
  color: var(--inactive) !important;
}
.timerScreen .timeUp {
  color: var(--text-color) !important;
}
.timerScreen .timeOver {
  color: var(--time-out) !important;
}
.timerScreen .timeWarn {
  color: var(--warn) !important;
}
.timerScreen .blink {
  color: transparent;
}
.timerScreen.blinkScreen {
  animation-name: keyframe-uhd2zyq;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@keyframes keyframe-uhd2zyq {
  50% {
    background-color: var(--blink);
  }
}

/* timer vars */
:root {
  --bg-color: #1c1c1e;
  --inactive: rgba(255, 255, 255, 0.22);
  --active: green;
  --warn: #ff8d00;
  --time-out: red;
  --text-color: rgba(255, 255, 255, 0.9);
  --blink: darkred;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
}
table thead tr, table thead th {
  border-bottom: solid #fdc300;
  text-align: left;
}
table th,
table td {
  padding: 12px 15px;
}
table tbody tr:nth-of-type(even) {
  background-color: #3c3c3c;
}
table tbody tr:hover {
  background-color: #2c2c2c;
}

#dropArea {
  border: 2px dashed #fdc300;
  border-radius: 20px;
  width: 270px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#dropArea.highlight {
  background-color: #fdc20071;
}
#dropArea p {
  margin-top: 0;
  text-align: center;
}
#dropArea input {
  display: none;
}
#dropArea #progressBars {
  width: 100%;
}
#dropArea #progressBars .progress-bar {
  height: 20px;
  width: 100%;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
#dropArea #progressBars .progress-bar .progress {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: black;
  text-align: center;
  background-color: #fdc300;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  transition: width 0.6s ease;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
}
#dropArea #progressBars .progress-bar .progress.active {
  animation-name: keyframe-uhd2zyt;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes keyframe-uhd2zyt {
  from {
    background-position: 40px 0;
    to {
      background-position: 0 0;
    }
  }
}
#dropArea #progressBars .progress-bar .progress.success {
  background-color: green;
  background-image: none;
  color: white;
}
#dropArea #progressBars .progress-bar .progress.error {
  background-color: red;
  background-image: none;
  color: white;
}

.presenterFiles #container {
  margin: 5vh 10vw;
}
.presenterFiles #header {
  display: flex;
  vertical-align: middle;
  font-size: 2rem;
  margin-bottom: 30px;
}
.presenterFiles #header img {
  width: 40px;
  margin-right: 10px;
}
.presenterFiles th[data-sort] {
  cursor: pointer;
}
.presenterFiles th[data-sort]::after {
  font-family: "Font Awesome 5 Pro";
  font-size: 1rem;
  content: " \f883";
}
.presenterFiles th[data-sort][data-sortorder=asc]::after {
  content: " \f15d";
}
.presenterFiles th[data-sort][data-sortorder=desc]::after {
  content: " \f882";
}

.checkbox {
  position: relative;
  z-index: 0;
  float: left;
  margin: 0;
  appearance: none;
}
.checkbox + label {
  margin-left: 2px;
}
.checkbox + label:before {
  font-family: "Font Awesome 5 Pro";
  color: #fdc300;
  display: inline-block;
  position: relative;
  content: "\f0c8";
  font-size: 1.1rem;
  z-index: 5;
  margin: 0 5px 0 0;
}
.checkbox:checked + label:before {
  content: "\f14a";
}
.checkbox:disabled + label {
  cursor: not-allowed;
  color: #575757;
}

body,
html {
  width: 100%;
  margin: 0;
  padding: 0;
  touch-action: manipulation;
}

body {
  height: 100%;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  background-color: #1c1c1e;
  color: rgba(255, 255, 255, 0.9);
  margin: 0 auto;
}
body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
body::-webkit-scrollbar-thumb {
  background: #fdc300;
  border-radius: 3px;
}
body::-webkit-scrollbar-track {
  background: white;
  border-radius: 3px;
}
body {
  scrollbar-face-color: #fdc300;
  scrollbar-track-color: white;
}
body.index, body.scan, body.app, body.offlinePage, body.unsupportedPage {
  max-height: 900px;
  max-width: 400px;
}

#titleBar {
  height: 40px;
  padding: 0 0 0 15px;
  background-color: #383840;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#titleBar #title-center {
  display: flex;
  height: inherit;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
}
#titleBar #title-center img {
  height: 19px;
  margin-right: 10px;
}
#titleBar #title-right {
  display: flex;
  height: inherit;
  font-size: 19px;
  align-items: center;
}
#titleBar #title-right #menu {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: right;
}
#titleBar #title-right #menu .active, #titleBar #title-right #menu:hover {
  background-color: #5e5e6b;
}
#titleBar #title-right #menu #menuBtn {
  width: 40px;
  justify-content: right;
  display: flex;
}
#titleBar #title-right #menu #menuBtn span {
  margin: auto;
}
#titleBar #title-right #menu .menuContent {
  position: absolute;
  background-color: #383840;
  z-index: 1;
  top: 40px;
  font-size: 0.8rem;
  user-select: none;
}
#titleBar #title-right #menu .menuContent .menuItem {
  padding: 10px 15px;
  cursor: pointer;
}
#titleBar #title-right #menu .menuContent .menuItem:hover {
  background-color: #5e5e6b;
}

h1 {
  margin: 10px 0;
  font-size: 1.5rem;
}

p {
  font-size: 1rem;
}

.logo {
  margin: 20px auto 30px;
  max-width: 400px;
  width: 80%;
}

.w80 {
  padding: 0 10% 0 10%;
}

.mt-30 {
  margin-top: 30px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 20px;
}

.indent-25 {
  padding-left: 25px;
  text-indent: -25px;
}

button {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}

.input-btn {
  -webkit-appearance: none;
  border: none;
  border-radius: 3px !important;
  background-color: #fdc300;
  color: black;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  height: 2rem;
  line-height: 2rem;
  width: 100%;
  padding: 0;
  cursor: pointer;
}

a, .link {
  color: #fdc300;
  text-decoration: underline;
  cursor: pointer;
}
a > .input-btn, .link > .input-btn {
  text-decoration: none;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  user-select: none;
  white-space: pre;
  align-items: flex-start;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.row {
  display: flex;
}

.row-1,
.row-2 {
  display: flex;
}

.row-1 {
  height: 40vh;
  font-size: calc(40vh * 0.4);
}

.row-2 {
  height: 5vh;
  font-size: calc(5vh * 0.5);
}

.row-3 {
  height: 2rem;
  margin: 30px 0 30px 0;
  display: flex;
  justify-content: space-around;
}

.center {
  justify-content: center;
}

.ctrl-btn {
  border: none;
  cursor: pointer;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  font-size: inherit;
  width: calc(50% - ( 5px / 2));
  padding: 0;
}
.ctrl-btn.active {
  filter: brightness(150%);
}
.row-1 .ctrl-btn {
  display: inline-flex;
}
.row-2 .ctrl-btn {
  background-color: #fdc300;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 600;
}

#left {
  background: darkred;
  color: red;
}

#right {
  background: darkgreen;
  color: #00b600;
}

.row-1 i {
  margin: auto;
  font-size: inherit;
}

.space-horizontal {
  height: 5px;
}

.space-vertical {
  width: 5px;
}

.switch-btn {
  display: block;
  width: 2rem;
}
.switch-btn input {
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
}
.switch-btn label {
  position: absolute;
  width: 2rem;
  font-size: 1.1rem;
  font-family: "Font Awesome 5 Pro";
  text-align: center;
}

.audio::before {
  content: "\f2e2";
  color: black;
}

input:checked + .audio::before {
  content: "\f6a8";
  color: #00b600;
}

.keyboard::before {
  content: "\f11c";
  color: black;
}

input:checked + .keyboard::before {
  content: "\f11c";
  color: #00b600;
}

/* --- TIMER --- */
.PresenterTimer {
  display: flex;
  align-items: center;
  height: 74px;
  width: 80%;
  margin: 20px auto;
  background: white;
  border: 2px solid #fdc300;
  border-radius: 3px;
  font-size: 5ex;
  font-weight: 600;
}
.PresenterTimer .timerField {
  color: black;
  display: flex;
  height: inherit;
  align-items: center;
  justify-content: center;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
}
.PresenterTimer .timerField .seperator {
  padding-bottom: 7px;
}
.PresenterTimer .blink {
  animation-name: keyframe-uhd2zyu;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@keyframes keyframe-uhd2zyu {
  50% {
    opacity: 0;
  }
}
.PresenterTimer .inactive {
  background-color: #828282;
}
.PresenterTimer .timeWarn {
  background-color: #ff8d00;
}
.PresenterTimer .timerField.timeOver {
  color: red;
}
.PresenterTimer .blinkScreen {
  background-color: white;
  animation-name: keyframe-uhd2zzi;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@keyframes keyframe-uhd2zzi {
  50% {
    background-color: red !important;
    color: black;
  }
}
.PresenterTimer .timer::before {
  content: "\f017";
  color: black;
}

/* Screen-Share */
#remoteScreen {
  width: 100%;
  margin-bottom: 15px;
}
#remoteScreen video {
  width: inherit;
}

.logo {
  margin: 20px auto 30px;
  max-width: 400px;
  width: 80%;
}

#footer {
  display: flex;
  flex-direction: column;
  width: inherit;
  max-width: inherit;
  justify-content: center;
  position: fixed;
  bottom: 0;
  margin: 15px 0 15px;
  height: 100px;
}
#footer img {
  display: block;
  margin: auto;
  width: 160px;
  height: 55px;
}

#legals {
  display: grid;
  margin-top: 15px;
  text-align: center;
  font-size: 10px;
}

.legalsText {
  padding-right: 10px;
}

.btn {
  -webkit-appearance: none;
  border: none;
  border-radius: 3px !important;
  background-color: #fdc300;
  color: black;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  height: 2rem;
  line-height: 2rem;
  width: 100%;
  padding: 0;
  cursor: pointer;
}
.btn.second {
  border: 1px solid #fdc300;
  border-radius: 3px !important;
  background-color: #1c1c1e;
  color: rgba(255, 255, 255, 0.9);
}
.btn.btn-sm {
  width: 2rem;
}
.btn.btn-fit {
  width: fit-content;
  padding: 0 1rem;
}
.btn.active {
  color: green;
}

.input-text {
  border: 2px solid #fdc300;
  border-radius: 5px;
  font-size: 1rem;
  height: 2rem;
  width: calc(100% - 14px);
  padding: 0;
  margin-bottom: 24px;
  padding-left: 5px;
  padding-right: 5px;
}

.red {
  color: red;
}

.green {
  color: green;
}

.bg-green {
  background-color: green;
}

.light-green {
  color: #00b600;
}

.space-24 {
  margin-bottom: 24px;
}

#qrShow {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
  backdrop-filter: blur(4px) brightness(60%);
  z-index: 100;
}
#qrShow .shareContainer {
  position: absolute;
  margin: 0 auto;
  left: calc(50vw - 212px / 2);
  top: calc(30vh - 212px / 2);
}
#qrShow .shareContainer #token {
  background-color: #ffffffe6;
  color: #1c1c1e;
  margin: -5px 0;
  padding: 0 15px 15px;
  text-align: center;
  font-weight: 600;
}
#qrShow .shareContainer div {
  margin-top: 25px;
}

.qr-code::before {
  content: "\f029";
  color: black;
}

.hide {
  display: none !important;
}

#loadingMessage {
  width: 100%;
  max-width: 320px;
  max-height: 320px;
  height: 80vw;
  text-align: center;
  background: #fdc2006b;
  border: #fdc300 1px solid;
}

#qr-video {
  width: 100%;
  max-width: 320px;
  max-height: 320px;
  border: #fdc300 1px solid;
}

#output {
  margin-top: 20px;
  background: #fdc2006b;
  padding: 10px;
  padding-bottom: 0;
}
#output div {
  padding-bottom: 10px;
  word-wrap: break-word;
}

#noQRFound {
  text-align: center;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(4px);
  z-index: 10;
}
.modal .modal-container {
  height: 90vh;
  width: 90vw;
  margin: auto;
  margin-top: 5vh;
  background-color: #1c1c1e;
  border: 1px solid #fdc300;
  border-radius: 5px;
}
.modal .modal-container .modal-header {
  background-color: #fdc300;
  color: #000;
  height: 2.25rem;
  display: flex;
  font-size: 1.5rem;
  font-weight: 600;
}
.modal .modal-container .modal-header .modal-title {
  margin-left: 1rem;
}
.modal .modal-container .modal-header .modal-close {
  cursor: pointer;
  margin-left: auto;
  margin-right: 1rem;
}
.modal .modal-container .modal-content {
  height: calc(100% - 2.25rem - 20px);
}
.modal .modal-container .modal-content iframe {
  width: calc(100% - 20px);
  height: 100%;
  border: 0;
  padding: 10px;
}

/*Desktop*/
@media only screen and (min-device-width: 1000px) and (orientation: landscape) {
  body.withVideo {
    max-height: 100vh;
    max-width: 100vw;
  }

  body.withVideo #content {
    display: grid;
    grid-template-columns: calc(70% - 4vw / 2) 4vw calc(30% - 4vw / 2);
    margin: 0 4vw;
    height: calc(100vh - 40px);
  }

  .PresenterTimer {
    grid-column: 3;
    align-self: center;
  }

  #remoteScreen {
    grid-column: 1;
    grid-row: 1/span 3;
    align-self: center;
  }

  #controls {
    grid-column: 3;
  }

  .row-1 {
    height: 50vh;
    font-size: calc(50vh * 0.4);
  }

  .row-3 {
    grid-column: 3;
  }

  .row-2 {
    height: 10vh;
    font-size: calc(10vh * 0.5);
  }
}
/* iPad */ 
@media only screen and (min-device-width: 768px) and (max-device-width: 1180px) and (min-device-height: 768px) and (max-device-height: 1365px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  body.withVideo {
    max-height: 100vh;
    max-width: 100vw;
  }

  body.withVideo #content {
    display: grid;
    grid-template-columns: calc(70% - 4vw / 2) 4vw calc(30% - 4vw / 2);
    margin: 0 4vw;
    height: calc(100vh - 40px);
  }

  .PresenterTimer {
    grid-column: 3;
    align-self: center;
  }

  #remoteScreen {
    grid-column: 1;
    grid-row: 1/span 3;
    align-self: center;
  }

  #controls {
    grid-column: 3;
  }

  .row-1 {
    height: 50vh;
    font-size: calc(50vh * 0.4);
  }

  .row-3 {
    grid-column: 3;
  }

  .row-2 {
    height: 10vh;
    font-size: calc(10vh * 0.5);
  }
}
/*iPad Pro Portrait*/ 
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  body {
    max-height: 100vh;
    max-width: 60vw;
  }

  .row-1 {
    height: 37vh;
    font-size: calc(37vh * 0.4);
  }
}
/* Portrait Smartphone*/
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) {
  body {
    max-height: 100vh;
    max-width: 100vw;
  }

  #menu .menuContent {
    right: 0;
  }

  .row-1 {
    height: 50vh;
    font-size: calc(50vh * 0.4);
  }

  body.withVideo .row-1 {
    height: 32vh;
    font-size: calc(32vh * 0.4);
  }
  body.withVideo .PresenterTimer {
    width: 130px;
    top: -7px;
    left: calc(50vw - (130px / 2));
    height: 30px;
    margin: 10px auto 10px;
    font-size: 1.5rem;
  }
  body.withVideo .PresenterTimer .timerField .seperator {
    padding-bottom: 3px;
  }

  table .wide {
    display: none;
  }
}
/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) {
  body {
    max-height: 100vh !important;
    max-width: 100vw !important;
  }

  #menu .menuContent {
    right: 0;
  }

  .row-1 {
    height: 40vh;
    font-size: calc(40vh * 0.4);
  }

  .row-2 {
    height: 20vh;
    font-size: calc(20vh * 0.5);
  }

  .row-3 {
    margin: 15px 0 30px 0;
    display: flex;
    justify-content: space-around;
  }

  #controls {
    margin-top: 5px;
  }

  .PresenterTimer {
    width: 130px;
    position: absolute;
    top: -7px;
    left: calc(50vw - (130px / 2));
    height: 30px;
    margin: 10px auto 10px;
    font-size: 1.5rem;
  }
  .PresenterTimer .timerField .seperator {
    padding-bottom: 3px;
  }

  #remoteScreen {
    display: none;
  }

  #footer {
    position: relative;
  }
}
@media only screen and (min-device-height: 320px) and (max-device-height: 480px) and (orientation: landscape) {
  body {
    max-height: 100vh !important;
    max-width: 100vw !important;
  }

  #menu .menuContent {
    right: 0;
  }

  .row-1 {
    height: 40vh;
    font-size: calc(40vh * 0.4);
  }

  .row-2 {
    height: 20vh;
    font-size: calc(20vh * 0.5);
  }

  .row-3 {
    margin: 15px 0 30px 0;
    display: flex;
    justify-content: space-around;
  }

  #controls {
    margin-top: 5px;
  }

  .PresenterTimer {
    width: 130px;
    position: absolute;
    top: -7px;
    left: calc(50vw - (130px / 2));
    height: 30px;
    margin: 10px auto 10px;
    font-size: 1.5rem;
  }
  .PresenterTimer .timerField .seperator {
    padding-bottom: 3px;
  }

  #remoteScreen {
    display: none;
  }

  #footer {
    position: relative;
  }
}
@media only screen and (min-device-height: 320px) and (max-device-height: 480px) and (orientation: landscape-primary) {
  body {
    max-height: 100vh !important;
    max-width: 100vw !important;
  }

  #menu .menuContent {
    right: 0;
  }

  .row-1 {
    height: 40vh;
    font-size: calc(40vh * 0.4);
  }

  .row-2 {
    height: 20vh;
    font-size: calc(20vh * 0.5);
  }

  .row-3 {
    margin: 15px 0 30px 0;
    display: flex;
    justify-content: space-around;
  }

  #controls {
    margin-top: 5px;
  }

  .PresenterTimer {
    width: 130px;
    position: absolute;
    top: -7px;
    left: calc(50vw - (130px / 2));
    height: 30px;
    margin: 10px auto 10px;
    font-size: 1.5rem;
  }
  .PresenterTimer .timerField .seperator {
    padding-bottom: 3px;
  }

  #remoteScreen {
    display: none;
  }

  #footer {
    position: relative;
  }
}