@import url("/assets/fonts/fa/css/all.min.css")
@import _mixins
@import _fonts
@import _timer
@import _vars
@import _table
@import fileUpload
@import presenterFiles
@import checkbox

$space-between-btns: 5px

body,
html
    width: 100%
    margin: 0
    padding: 0
    touch-action: manipulation

body
    @include scrollbars(5px, $main-color, white)

    height: 100%
    font-family: $font-family
    font-size: 1rem
    background-color: $bg-color
    color: $text-color
    margin: 0 auto
    &.index,
    &.scan,
    &.app,
    &.offlinePage,
    &.unsupportedPage
        max-height: 900px
        max-width: 400px

#titleBar
    $titlebarHeight: 40px
    height: $titlebarHeight
    padding: 0 0 0 15px
    background-color: $title-bar
    color: white
    display: flex
    justify-content: space-between
    align-items: center

    #title-center
        display: flex
        height: inherit
        font-size: 14px
        font-weight: 600
        align-items: center

        img
            height: 19px
            margin-right: 10px

    #title-right
        display: flex
        height: inherit
        font-size: 19px
        align-items: center

        #menu
            cursor: pointer
            width: $titlebarHeight
            height: $titlebarHeight
            display: flex
            align-items: center
            justify-content: right

            .active,
            &:hover
                background-color: $title-bar-hover

            #menuBtn
                width: $titlebarHeight
                justify-content: right
                display: flex
                span
                    margin: auto

            .menuContent
                position: absolute
                background-color: $title-bar
                z-index: 1
                top: $titlebarHeight
                font-size: 0.8rem
                user-select: none

                .menuItem
                    padding: 10px 15px
                    cursor: pointer

                    &:hover
                        background-color: $title-bar-hover

h1
    margin: 10px 0
    font-size: 1.5rem

p
    font-size: 1rem

.logo
    margin: 20px auto 30px
    max-width: 400px
    width: 80%

.w80
    padding: 0 10% 0 10%

.mt-30
    margin-top: 30px

.mr-5
    margin-right: 5px

.mb-25
    margin-bottom: 25px

.ml-25
    margin-left: 20px
.indent-25
    padding-left: 25px
    text-indent: -25px
button
    font-family: $font-family

.input-btn
    -webkit-appearance: none
    border: none
    border-radius: 3px !important
    background-color: $main-color
    color: $btn-text-color
    font-weight: 600
    font-size: 1rem
    text-align: center
    height: 2rem
    line-height: 2rem
    width: 100%
    padding: 0
    cursor: pointer

a, .link
    color: $main-color
    text-decoration: underline
    cursor: pointer
    > .input-btn
        text-decoration: none
        letter-spacing: normal
        word-spacing: normal
        text-transform: none
        text-indent: 0px
        text-shadow: none
        user-select: none
        white-space: pre
        align-items: flex-start
        text-align: center
        display: inline-block
        cursor: pointer
.row
    display: flex
.row-1,
.row-2
    display: flex

.row-1
    $height: 40vh
    height: $height
    font-size: calc(#{$height} * 0.4)

.row-2
    $height: 5vh
    height: $height
    font-size: calc(#{$height} * 0.5)

.row-3
    height: 2rem
    margin: 30px 0 30px 0
    display: flex
    justify-content: space-around

.center
    justify-content: center

.ctrl-btn
    border: none
    cursor: pointer
    -webkit-transition: 0.2s
    transition: 0.2s
    font-size: inherit
    width: calc(50% - ( #{$space-between-btns} / 2))
    padding: 0
    &.active
        filter: brightness(150%)

    .row-1 &
        display: inline-flex

    .row-2 &
        background-color: $main-color
        color: $on-main-color
        font-weight: 600

#left
    background: $dark-red
    color: $red

#right
    background: $dark-green
    color: $light-green

.row-1 i
    margin: auto
    font-size: inherit

.space-horizontal
    height: $space-between-btns

.space-vertical
    width: $space-between-btns

.switch-btn
    display: block
    width: 2rem

    & input
        opacity: 0
        width: 0
        height: 0
        margin: 0

    label
        position: absolute
        width: 2rem
        font-size: 1.1rem
        font-family: $fa
        text-align: center

.audio::before
    content: "\f2e2"
    color: $btn-text-color

input:checked + .audio::before
    content: "\f6a8"
    color: $light-green

.keyboard::before
    content: "\f11c"
    color: $btn-text-color

input:checked + .keyboard::before
    content: "\f11c"
    color: $light-green

/* --- TIMER --- */
.PresenterTimer
    display: flex
    align-items: center
    height: 74px
    width: 80%
    margin: 20px auto
    background: white
    border: 2px solid $main-color
    border-radius: 3px
    font-size: 5ex
    font-weight: 600

    .timerField
        color: black
        display: flex
        height: inherit
        align-items: center
        justify-content: center
        width: 100%
        width: -webkit-fill-available
        width: -moz-available

        .seperator
            padding-bottom: 7px

    @mixin keyframes($duration)
        $name: keyframe-#{unique-id( )}
        animation-name: $name
        animation-duration: $duration
        animation-iteration-count: infinite
        @keyframes #{$name}
            @content

    .blink
        @include keyframes(1s)
            #{50%}
                opacity: 0

    .inactive
        background-color: $light-grey

    .timeWarn
        background-color: $orange

    .timerField
        &.timeOver
            color: red

    .blinkScreen
        background-color: white

        @include keyframes(1s)
            #{50%}
                background-color: $red !important
                color: black

    .timer::before
        content: "\f017"
        color: $btn-text-color

/* Screen-Share */

#remoteScreen
    width: 100%
    margin-bottom: 15px

    & video
        width: inherit

.logo
    margin: 20px auto 30px
    max-width: 400px
    width: 80%

#footer
    display: flex
    flex-direction: column
    width: inherit
    max-width: inherit
    justify-content: center
    position: fixed
    bottom: 0
    margin: 15px 0 15px
    height: 100px

    img
        display: block
        margin: auto
        width: 160px
        height: 55px

#legals
    display: grid
    margin-top: 15px
    text-align: center
    font-size: 10px

.legalsText
    padding-right: 10px

.btn
    -webkit-appearance: none
    border: none
    border-radius: 3px !important
    background-color: $main-color
    color: $btn-text-color
    font-family: $font-family
    font-weight: 600
    font-size: 1rem
    text-align: center
    height: 2rem
    line-height: 2rem
    width: 100%
    padding: 0
    cursor: pointer

    &.second
        border: 1px solid $main-color
        border-radius: 3px !important
        background-color: $bg-color
        color: $text-color

    &.btn-sm
        width: 2rem

    &.btn-fit
        width: fit-content
        padding: 0 1rem

    &.active
        color: $green

.input-text
    border: 2px solid $main-color
    border-radius: 5px
    font-size: 1rem
    height: 2rem
    width: calc(100% - 14px)
    padding: 0
    margin-bottom: 24px
    padding-left: 5px
    padding-right: 5px

.red
    color: $red

.green
    color: $green
.bg-green
    background-color: $green

.light-green
    color: $light-green

.space-24
    margin-bottom: 24px

#qrShow
    width: 100vw
    height: 100vh
    top: 0
    left: 0
    position: absolute
    backdrop-filter: blur(4px) brightness(60%)
    z-index: 100

    .shareContainer
        $width: 212px
        position: absolute
        margin: 0 auto
        left: calc(50vw - #{$width} / 2)
        top: calc(30vh - #{$width} / 2)

        #token
            background-color: #ffffffe6
            color: #1c1c1e
            margin: -5px 0
            padding: 0 15px 15px
            text-align: center
            font-weight: 600

        div
            margin-top: 25px

.qr-code::before
    content: "\f029"
    color: $btn-text-color

.hide
    display: none !important

#loadingMessage
    width: 100%
    max-width: 320px
    max-height: 320px
    height: 80vw
    text-align: center
    background: #fdc2006b
    border: $main-color 1px solid

#qr-video
    width: 100%
    max-width: 320px
    max-height: 320px
    border: $main-color 1px solid

#output
    margin-top: 20px
    background: #fdc2006b
    padding: 10px
    padding-bottom: 0

    & div
        padding-bottom: 10px
        word-wrap: break-word

#noQRFound
    text-align: center

.noselect
    -webkit-touch-callout: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

.modal
    position: absolute
    top: 0
    left: 0
    height: 100vh
    width: 100vw
    -webkit-backdrop-filter: blur(20px)
    backdrop-filter: blur(4px)
    z-index: 10
    $modal-header-height: 2.25rem

    .modal-container
        height: 90vh
        width: 90vw
        margin: auto
        margin-top: 5vh
        background-color: $bg-color
        border: 1px solid $main-color
        border-radius: 5px

        .modal-header
            background-color: $main-color
            color: $black
            height: $modal-header-height
            display: flex
            font-size: 1.5rem
            font-weight: 600

            .modal-title
                margin-left: 1rem

            .modal-close
                cursor: pointer
                margin-left: auto
                margin-right: 1rem

        .modal-content
            height: calc(100% - #{$modal-header-height} - 20px)
            iframe
                width: calc(100% - 20px)
                height: 100%
                border: 0
                padding: 10px

@mixin desktop
    body.withVideo
        max-height: 100vh
        max-width: 100vw

    body.withVideo
        #content
            $midspace: 4vw
            display: grid
            grid-template-columns: calc(70% - #{$midspace} / 2) $midspace calc(30% - #{$midspace} / 2)
            margin: 0 $midspace
            height: calc(100vh - 40px)

    .PresenterTimer
        grid-column: 3
        align-self: center

    #remoteScreen
        grid-column: 1
        grid-row: 1 / span 3
        align-self: center

    #controls
        grid-column: 3

    .row-1
        $height: 50vh
        height: $height
        font-size: calc(#{$height} * 0.4)

    .row-3
        grid-column: 3

    .row-2
        $height: 10vh
        height: $height
        font-size: calc(#{$height} * 0.5)

@mixin landscape-smartphone
    body
        max-height: 100vh !important
        max-width: 100vw !important

    #menu
        .menuContent
            right: 0

    .row-1
        $height: 40vh
        height: $height
        font-size: calc(#{$height} * 0.4)

    .row-2
        $height: 20vh
        height: $height
        font-size: calc(#{$height} * 0.5)

    .row-3
        margin: 15px 0 30px 0
        display: flex
        justify-content: space-around

    #controls
        margin-top: $space-between-btns

    .PresenterTimer
        $width: 130px
        width: $width
        position: absolute
        top: -7px
        left: calc(50vw - (#{$width} / 2))
        height: 30px
        margin: 10px auto 10px
        font-size: 1.5rem

        .timerField
            .seperator
                padding-bottom: 3px

    #remoteScreen
        display: none

    #footer
        position: relative

@mixin portrait
    body
        max-height: 100vh
        max-width: 100vw

    #menu
        .menuContent
            right: 0
    .row-1
        $height: 50vh
        height: $height
        font-size: calc(#{$height} * 0.4)

    body.withVideo
        .row-1
            $height: 32vh
            height: $height
            font-size: calc(#{$height} * 0.4)

        .PresenterTimer
            $width: 130px
            width: $width
            top: -7px
            left: calc(50vw - (#{$width} / 2))
            height: 30px
            margin: 10px auto 10px
            font-size: 1.5rem

            .timerField
                .seperator
                    padding-bottom: 3px

@mixin portrait-ipad
    body
        max-height: 100vh
        max-width: 60vw

    .row-1
        $height: 37vh
        height: $height
        font-size: calc(#{$height} * 0.4)

/*Desktop*/
@media only screen and (min-device-width: 1000px) and (orientation: landscape)
    @include desktop

/* iPad */ 
@media only screen and (min-device-width: 768px) and (max-device-width: 1180px) and (min-device-height: 768px) and (max-device-height: 1365px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1)
    @include desktop

/*iPad Pro Portrait*/ 
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5)
    @include portrait-ipad
    @include table-portrait-ipad

/* Portrait Smartphone*/
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait)
    @include portrait
    @include table-portrait

/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape)
    @include landscape-smartphone
    @include table-landscape-smartphone
@media only screen and (min-device-height: 320px) and (max-device-height: 480px) and (orientation: landscape)
    @include landscape-smartphone
    @include table-landscape-smartphone
@media only screen and (min-device-height: 320px) and (max-device-height: 480px) and (orientation: landscape-primary)
    @include landscape-smartphone
    @include table-landscape-smartphone
